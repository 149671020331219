.pagination {
  margin: 0;
  padding: 0 !important;
  color: #7aba4c;
  z-index: 3;
}

.page-item.active .page-link {
  /* z-index: 1;
  color: #fff;
 
  border-color: transparent;
  border-radius: 0.625rem;
  margin-right: 2px;
  margin-left: 2px;
  padding-inline: 10px!important; */
  background-color: rgb(106, 73, 242);
  z-index: 1;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: white;
  margin:0px 7px;
  border: 0.0625rem solid #E6E6E6;

}
.page-item.disabled .page-link {
  color: rgb(106, 73, 242);
  border-radius: 0.625rem;
  border: 0.0625rem solid #E6E6E6;
  font-size: 13px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
    align-items: center;
    justify-content: center;
}
.page-link {
  border: none;
  color:rgb(106, 73, 242);
  background-color: transparent;
}
.page-link:hover {
  color: rgb(106, 73, 242);
  border-radius: 10px;
  /* background-color: transparent; */
}
